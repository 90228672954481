



















































































































































import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { ViewItem, User, ComponentType } from '@/models'
import * as componentTypes from '@/components/componentTypes'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    Block: () => import('@/components/componentTypes/blocks/View.vue')
  }
})
export default class ViewComponentHeader extends Vue {
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem
  @Prop({ type: String, default: '' }) environmentId!: string
  @Prop({ type: String, default: '' }) title!: string
  @Prop({ type: Boolean, default: false }) editing!: boolean

  @Inject({ default: () => () => ({}) }) componentHeaderOptions!: () => Record<
    string,
    any
  >

  fullSize = false
  helpOpen = false

  openHelp() {
    this.helpOpen = true
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get adminLink() {
    if (window.mobileApp || !this.environmentId) return ''
    if (getEnv('VUE_APP_ADMIN_DOMAIN')) {
      return `${document.location.protocol}//${getEnv(
        'VUE_APP_ADMIN_DOMAIN'
      )}/${this.environmentId}/components`
    } else if (getEnv('VUE_APP_ADMIN_DOMAIN_V1')) {
      return `${document.location.protocol}//${getEnv(
        'VUE_APP_ADMIN_DOMAIN_V1'
      )}/${this.environmentId}`
    }
  }

  get componentType() {
    // @ts-ignore
    const matchingType = Object.keys(componentTypes)
      // @ts-ignore
      .find((t) => componentTypes[t].name === this.itemDefinition.type)
    // @ts-ignore
    const cType = componentTypes[matchingType]
    return cType as ComponentType<any>
  }

  get componentId() {
    // @ts-ignore
    return this.itemDefinition[`${this.componentType.name}Id`]
  }

  get helpButtonColor() {
    return this.itemDefinition.titleBackground ? '' : 'primary'
  }

  get extended() {
    return !!(
      this.itemDefinition.titleBackground &&
      this.itemDefinition.titleBackground.indexOf('http') === 0
    )
  }

  get backgroundStyle() {
    if (this.itemDefinition.titleBackground) {
      return {
        background:
          this.itemDefinition.titleBackground.indexOf('http') === 0
            ? `url(${this.itemDefinition.titleBackground}) center / cover`
            : this.itemDefinition.titleBackground,
        'margin-bottom': '1em'
      }
    }
    return {}
  }

  get shadowStyle() {
    if (this.itemDefinition.titleBackground) {
      return {
        'text-shadow':
          this.itemDefinition.titleBackground.indexOf('http') === 0
            ? '0 0 5px black'
            : 'none'
      }
    }
    return {}
  }

  get color() {
    if (this.itemDefinition.titleBackground) {
      return this.itemDefinition.titleBackground.indexOf('http') === 0
        ? 'transparent'
        : this.itemDefinition.titleBackground
    }
    return 'transparent'
  }

  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }

  get editLinks() {
    return this.isAdmin && this.adminLink && this.$store.state.app.editLinks
  }

  get shouldDisplay() {
    if (this.componentHeaderOptions().noHeader) return false
    return (
      (this.$slots.default && this.$slots.default?.length) ||
      this.editing ||
      this.editLinks ||
      this.itemDefinition.fullSize ||
      !!this.title
    )
  }
}
